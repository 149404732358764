import React, { useState } from "react";
import { observer } from "mobx-react-lite";
// import useSWRInfinite from "swr/infinite";

import { SectionTab } from "polotno/side-panel";
import MdPhotoLibrary from "@meronex/icons/md/MdPhotoLibrary";

import { ImagesGrid } from "polotno/side-panel/images-grid";

export const MyTemplatesPanel = observer(({ store }) => {
    // load data
    // const { data, error, size, setSize } = useSWRInfinite(
    //   (index) => `./templates/page${index + 1}.json`
    // );

    // // do some calculations from the hook above
    // const isLoadingInitialData = !data && !error;
    // const isLoading =
    //   isLoadingInitialData ||
    //   !!(size > 0 && data && typeof data[size - 1] === "undefined");
    // const isEmpty = data?.[0]?.length === 0;
    // console.log('data',data)
    // console.log('error',error)
    // const isReachingEnd = isEmpty || (data && data[0]?.total_pages === size);

    // const loadMore = () => {
    //   if (!isReachingEnd) {
    //     setSize(size + 1);
    //   }
    // };

    const [data, setData] = useState([]);

    React.useEffect(() => {
        // load data
        fetch(`./templates/page1.json`)
            .then((res) => res.json())
            .then((data) => {
              console.log(data)  
              setData(data);
            });
    }, []);

    return (
        <div style={{ height: "100%" }}>
            <ImagesGrid
                shadowEnabled={false}
                images={data.items}
                getPreview={(item) => `/templates/${item.preview}`}
                // isLoading={isLoading}
                onSelect={async (item) => {
                    // download selected json
                    const req = await fetch(`/templates/${item.json}`);
                    const json = await req.json();
                    // just inject it into store
                    store.loadJSON(json);
                }}
                // loadMore={loadMore}
                rowsNumber={1}
            />
        </div>
    );
});

// define the new custom section
export const MyTemplatesSection = {
    name: "myTemplates",
    Tab: (props) => (
        <SectionTab name="Simon Template" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: MyTemplatesPanel,
};
