import React from "react";
import { observer } from "mobx-react-lite";
import { InputGroup } from "@blueprintjs/core";

import { getImageSize } from "polotno/utils/image";

import { ImagesGrid } from "polotno/side-panel/images-grid";
import { DOMAIN_URL } from "../constants";

import qs from "qs";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const PhotosPanelPng_local = observer(({ store }) => {
    const [images, setImages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [transact, setTransact] = React.useState("");
    const [pageNum, setPageNum] = React.useState(1);
    const pageSize = 30;

    let location = useLocation();
    React.useEffect(() => {
        // let transactParam = location.hash.replace("#", "");
        let paramsSearch = location.search.replace("?", "");
        let myParams = qs.parse(paramsSearch);
        setTransact(myParams["transact"]);
    }, [location]);

    React.useEffect(() => {
        if (transact) {
            setImages([]);
            loadImages();
        }
    }, [transact]);

    const loadImages = () => {
        setIsLoading(true);
        setTimeout(() => {
            apiGetImageTransact(transact, pageNum, pageSize).then((res) => {
                try {
                    console.log(res);
                    let data = res.data;
                    if (res.status === 1) {
                        if (Object.keys(data).length) {
                            let myImgs = [];
                            data.forEach((e) => {
                                myImgs.push({
                                    url: DOMAIN_URL + "/api/curl_image/?type=png&img=" + e.image,
                                });
                            });
                            setPageNum(pageNum + 1);
                            setImages(images.concat(myImgs));
                            console.log(myImgs);
                        }
                    } else {
                        console.log("error:" + res.message);
                    }

                    setIsLoading(false);
                } catch (error) {
                    console.log("error apiGetImageTransact", error);
                    setIsLoading(false);
                }
            });
        }, 3000);
    };

    const loadMore = () => {
        if (!isLoading) {
            loadImages();
        }
    };

    const apiGetImageTransact = async (transact, pageno, pagesize) => {
        try {
            let urlRequest =
                DOMAIN_URL +
                "/api/getData.php?func=getImageSimonProduction" +
                "&type=png" +
                "&pageno=" +
                pageno +
                "&pagesize=" +
                pagesize;
            console.log("urlRequest", urlRequest);
            const response = await axios.get(urlRequest, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers": "*",
                },
            });
            let json = await response.data;
            return json;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <InputGroup
                leftIcon="search"
                placeholder="Search..."
                onChange={(e) => {
                    loadImages();
                }}
                style={{
                    marginBottom: "20px",
                }}
            />
            <p>Hình png mới: </p>
            {/* you can create yur own custom component here */}
            {/* but we will use built-in grid component */}
            <ImagesGrid
                images={images}
                getPreview={(image) => image.url}
                onSelect={async (image) => {
                    const { width, height } = await getImageSize(image.url);
                    store.activePage.addElement({
                        type: "image",
                        src: image.url,
                        width: 300,
                        height: (300 / width) * height,
                        x: 0,
                        y: 0,
                    });
                }}
                rowsNumber={2}
                isLoading={isLoading}
                loadMore={loadMore}
            />
        </div>
    );
});
