import React from "react";
import ReactDOM from "react-dom";
import localforage from "localforage";

import "./index.css";
import App from "./App";
import { createStore } from "polotno/model/store";

import { BrowserRouter as Router, Switch } from "react-router-dom";

const store = createStore({ key: "nFA5H9elEytDyPyvKL7T" });
store.addFont({
    fontFamily: "UTMAvoBold",
    url: "assets/fonts/UTMAvoBold.ttf",
});
store.addFont({
    fontFamily: "UTMAvoBold_Italic",
    url: "assets/fonts/UTMAvoBold_Italic.ttf",
});
store.addFont({
    fontFamily: "UTMAvo",
    url: "assets/fonts/UTMAvo.ttf",
});
store.addFont({
    fontFamily: "SVNAmperzand",
    url: "assets/fonts/SVNAmperzand.ttf",
});
store.addFont({
    fontFamily: "SVN-ALoveOfThunder",
    url: "assets/fonts/SVN-SVN-ALoveOfThunder.ttf",
});
store.addFont({
    fontFamily: "nabila",
    url: "assets/fonts/nabila.ttf",
});

store.addFont({
    fontFamily: "ArimaMadurai-Regular",
    url: "assets/fonts/ArimaMadurai-Regular.ttf",
});
store.addFont({
    fontFamily: "EncodeSansCondensed-Regular",
    url: "assets/fonts/EncodeSansCondensed-Regular.ttf",
});
store.addFont({
    fontFamily: "DancingScript-VariableFont_wght",
    url: "assets/fonts/DancingScript-VariableFont_wght.ttf",
});
store.addFont({
    fontFamily: "Lobster-Regular",
    url: "assets/fonts/Lobster-Regular.ttf",
});
store.addFont({
    fontFamily: "Raleway-VariableFont_wght",
    url: "assets/fonts/Raleway-VariableFont_wght.ttf",
});
store.addFont({
    fontFamily: "BonheurRoyale-Regular",
    url: "assets/fonts/BonheurRoyale-Regular.ttf",
});
store.addFont({ fontFamily: "Arial", url: "assets/fonts/Arial.ttf" });
store.addFont({ fontFamily: "Times-New-Roman", url: "assets/fonts/Times-New-Roman.ttf" });

store.addFont({ fontFamily: "FractisAlt", url: "assets/fonts/FractisAlt.ttf" });
store.addFont({
    fontFamily: "RomanticCoupleScript",
    url: "assets/fonts/RomanticCoupleScript.ttf",
});
store.addFont({ fontFamily: "stoner", url: "assets/fonts/stoner.ttf" });
store.addFont({
    fontFamily: "SVN-Cookies",
    url: "assets/fonts/SVN-Cookies.ttf",
});
store.addFont({
    fontFamily: "SVN-Dancingscript",
    url: "assets/fonts/SVN-Dancingscript.ttf",
});
store.addFont({
    fontFamily: "SVN-EveryMoviEveryNight",
    url: "assets/fonts/SVN-EveryMoviEveryNight.ttf",
});
store.addFont({
    fontFamily: "SVN-Lobster",
    url: "assets/fonts/SVN-Lobster.ttf",
});
store.addFont({
    fontFamily: "SVN-MarpesiaPro",
    url: "assets/fonts/SVN-MarpesiaPro.ttf",
});
store.addFont({
    fontFamily: "SVN-NexaRushSansBlack",
    url: "assets/fonts/SVN-NexaRushSansBlack.ttf",
});
store.addFont({
    fontFamily: "SVN-NexaRushScript",
    url: "assets/fonts/SVN-NexaRushScript.ttf",
});
store.addFont({ fontFamily: "SVN-Sunday", url: "assets/fonts/SVN-Sunday.ttf" });
store.addFont({
    fontFamily: "TheQueenthine",
    url: "assets/fonts/TheQueenthine.ttf",
});
store.addFont({
    fontFamily: "UTMBryantLG_0",
    url: "assets/fonts/UTMBryantLG_0.ttf",
});
store.addFont({
    fontFamily: "UTMBryantLG",
    url: "assets/fonts/UTMBryantLG.ttf",
});
store.addFont({ fontFamily: "UTMNeutra", url: "assets/fonts/UTMNeutra.ttf" });
store.addFont({
    fontFamily: "VNI-ChopsNormal",
    url: "assets/fonts/VNI-ChopsNormal.ttf",
});

window.store = store;

localforage.getItem("polotno-state", function (err, json) {
    if (json) {
        store.loadJSON(json);
    }
    if (!store.pages.length) {
        store.addPage();
    }
});

store.on("change", () => {
    try {
        const json = store.toJSON();
        localforage.setItem("polotno-state", json);
    } catch (e) {}
});

ReactDOM.render(
    <Router>
        <Switch>
            <React.StrictMode>
                <App store={store} />
            </React.StrictMode>
        </Switch>
    </Router>,
    document.getElementById("root")
);
