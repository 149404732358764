import React from "react";
import { observer } from "mobx-react-lite";
import { InputGroup, Button } from "@blueprintjs/core";

import { getImageSize } from "polotno/utils/image";

import { ImagesGrid } from "polotno/side-panel/images-grid";
import { PIXABAY_API_KEY } from "../constants";

export const PhotosPanelPexel = observer(({ store }) => {
    const [images, setImages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [query, setQuery] = React.useState("");
    const [pageNum1, setPageNum1] = React.useState(1);
    const pageSize = 30;

    // React.useEffect(() => {
    //   setImages([]);
    //   if (query) {
    //     if (!isLoading) {
    //       if (query.length > 2) {
    //         loadImages(true);
    //       }
    //     }
    //   }
    // }, [query]);

    const loadImages = (firstPage = false) => {
        if (query) {
            const getPageNum1 = firstPage ? 1 : pageNum1;
            setIsLoading(true);
            setTimeout(() => {
                let urlRequest =
                    "https://pixabay.com/api/?key=" +
                    PIXABAY_API_KEY +
                    "&q=" +
                    query +
                    "&lang=vi&image_type=photo" +
                    "&page=" +
                    getPageNum1 +
                    "&per_page=" +
                    pageSize;
                console.log(urlRequest);
                fetch(urlRequest)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            console.log("result", result);
                            try {
                                if (result.hits && Object.keys(result.hits).length) {
                                    let data = result.hits;
                                    let myImgs = [];
                                    data.forEach((e) => {
                                        myImgs.push({
                                            preview: e.previewURL,
                                            url: e.webformatURL,
                                        });
                                    });
                                    if (firstPage) {
                                        setImages(myImgs);
                                    } else {
                                        setImages(images.concat(myImgs));
                                    }
                                }
                                setIsLoading(false);
                                setPageNum1(pageNum1 + 1);
                            } catch (error) {
                                console.log("error get pixabay", error);
                                setIsLoading(false);
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            }, 3000);
        }
    };

    const loadMore = () => {
        if (!isLoading) {
            loadImages();
        }
    };

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <InputGroup
                // leftIcon="search"
                placeholder="Tìm hình..."
                rightElement={
                    <Button
                        icon="search"
                        minimal
                        onClick={() => {
                            if (query.length > 0) {
                                setImages([]);
                                loadImages(true);
                            } else {
                                alert("Vui lòng nhập từ khóa cần tìm");
                            }
                        }}
                    ></Button>
                }
                onChange={(e) => {
                    let value = e.target.value;
                    setQuery(value);
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        if (query.length > 0) {
                            setImages([]);
                            loadImages(true);
                        } else {
                            alert("Vui lòng nhập từ khóa cần tìm");
                        }
                    }
                }}
                style={{
                    marginBottom: "20px",
                }}
            />

            <p>Tìm kiếm hình: </p>
            {/* you can create yur own custom component here */}
            {/* but we will use built-in grid component */}
            <ImagesGrid
                images={images}
                getPreview={(image) => image.preview}
                onSelect={async (image) => {
                    const { width, height } = await getImageSize(image.url);
                    store.activePage.addElement({
                        type: "image",
                        src: image.url,
                        width: 300,
                        height: (300 / width) * height,
                        x: 0,
                        y: 0,
                    });
                }}
                rowsNumber={2}
                isLoading={isLoading}
                loadMore={loadMore}
            />
        </div>
    );
});
