import React from "react";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";

import Topbar from "./topbar";
import { loadJSONFile } from "./file";

import { PhotosPanelBg } from "./CustomPanel/PhotosPanelBg";
import { PhotosPanelBg_trasua } from "./CustomPanel/PhotosPanelBg_trasua";
import { PhotosPanelBg_mypham } from "./CustomPanel/PhotosPanelBg_mypham";
import { PhotosPanelBg_myphamxanh } from "./CustomPanel/PhotosPanelBg_myphamxanh";
import { PhotosPanelBg_tet } from "./CustomPanel/PhotosPanelBg_tet";
import { PhotosPanelBg_noel } from "./CustomPanel/PhotosPanelBg_noel";
import { PhotosPanelBg_donglanh } from "./CustomPanel/PhotosPanelBg_donglanh";
import { PhotosPanelPng } from "./CustomPanel/PhotosPanelPng";
import { PhotosPanelPngWed } from "./CustomPanel/PhotosPanelPngWed";
import { PhotosPanelOri } from "./CustomPanel/PhotosPanelOri";
import { PhotosPanelTemp } from "./CustomPanel/PhotosPanelTemp";
import { PhotosPanelPexel } from "./CustomPanel/PhotosPanelPexel";
import { ContentPanel } from "./CustomPanel/ContentPanel";
import { PhotosPanelPngAll } from "./CustomPanel/PhotosPanelPngAll";

import { PhotosPanelPng_local } from "./CustomPanel/PhotosPanelPng_local";
import { PhotosPanelBg_local } from "./CustomPanel/PhotosPanelBg_local";

import {
    TemplatesSection,
    TextSection,
    ElementsSection,
    UploadSection,
    BackgroundSection,
    SizeSection,
    PhotosSection,
} from "polotno/side-panel";
import { MyTemplatesSection } from "./CustomPanel/templates-panel";
import { SectionTab } from "polotno/side-panel";
// import our own icon
import MdPhotoLibrary from "@meronex/icons/md/MdPhotoLibrary";

import qs from "qs";
import { useLocation } from "react-router-dom";

const PanelPng_local = {
    name: "png_local",
    Tab: (props) => (
        <SectionTab name="PNG mới" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelPng_local,
};

const PanelBg_local = {
    name: "bg_local",
    Tab: (props) => (
        <SectionTab name="BG food" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_local,
};

const PanelBg_trasua = {
    name: "bg_trasua",
    Tab: (props) => (
        <SectionTab name="BG trà sữa" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_trasua,
};

const PanelBg_mypham = {
    name: "bg_mypham",
    Tab: (props) => (
        <SectionTab name="BG mỹ phẩm" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_mypham,
};

const PanelBg_myphamxanh = {
    name: "bg_myphamxanh",
    Tab: (props) => (
        <SectionTab name="BG mỹ phẩm xanh" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_myphamxanh,
};

const PanelBg_tet = {
    name: "bg_tet",
    Tab: (props) => (
        <SectionTab name="BG tết" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_tet,
};

const PanelBg_noel = {
    name: "bg_noel",
    Tab: (props) => (
        <SectionTab name="BG noel" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_noel,
};

const PanelBg_donglanh = {
    name: "bg_donglanh",
    Tab: (props) => (
        <SectionTab name="BG đông lạnh" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg_donglanh,
};

// define the new custom section
const СustomPhotosBg = {
    name: "myPhotos",
    Tab: (props) => (
        <SectionTab name="Simon Background" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelBg,
};

const СustomPhotosPng = {
    name: "myPhotos2",
    Tab: (props) => (
        <SectionTab name="Simon hình png" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelPng,
};

const СustomPhotosPngWed = {
    name: "pngWed",
    Tab: (props) => (
        <SectionTab name="Cưới PNG" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelPngWed,
};

const СustomPhotosOri = {
    name: "myPhotosOri",
    Tab: (props) => (
        <SectionTab name="Hình gốc" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelOri,
};

const СustomPhotosTemp = {
    name: "myPhotosTemp",
    Tab: (props) => (
        <SectionTab name="Hình cắt" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelTemp,
};

const СustomPhotosPexel = {
    name: "myPhotosPexel",
    Tab: (props) => (
        <SectionTab name="Tìm hình" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelPexel,
};

const СustomPhotosAll = {
    name: "myPngAll",
    Tab: (props) => (
        <SectionTab name="Nhóm PNG" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: PhotosPanelPngAll,
};

const СustomTextContent = {
    name: "myText",
    Tab: (props) => (
        <SectionTab name="Content" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: ContentPanel,
};

// we will have just two sections
const sections = [
    TemplatesSection,
    MyTemplatesSection,
    TextSection,
    СustomTextContent,
    СustomPhotosPexel,
    СustomPhotosBg,
    PanelPng_local,
    PanelBg_local,
    PanelBg_trasua,
    PanelBg_mypham,
    PanelBg_myphamxanh,
    PanelBg_tet,
    PanelBg_noel,
    PanelBg_donglanh,
    СustomPhotosPng,
    СustomPhotosAll,
    СustomPhotosPngWed,
    СustomPhotosOri,
    СustomPhotosTemp,
    PhotosSection,
    ElementsSection,
    UploadSection,
    BackgroundSection,
    // we will replace default resize with our own
    SizeSection,
];

const useHeight = () => {
    const [height, setHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setHeight(window.innerHeight);
        });
    }, []);
    return height;
};

const App = ({ store }) => {
    const handleDrop = (ev) => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === "file") {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    loadJSONFile(file, store);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                loadJSONFile(ev.dataTransfer.files[i], store);
            }
        }
    };

    const height = useHeight();

    const [role, setRole] = React.useState("cus");
    const [transact, setTransact] = React.useState("");

    const location = useLocation();
    React.useEffect(() => {
        console.log(location);
        let paramsSearch = location.search.replace("?", "");
        let myParams = qs.parse(paramsSearch);
        setRole(myParams["role"] ? myParams["role"] : "cus");
        setTransact(myParams["transact"]);
    }, [location]);

    if (transact) {
        return (
            <div
                style={{
                    width: "100vw",
                    height: height + "px",
                    display: "flex",
                    flexDirection: "column",
                }}
                onDrop={handleDrop}
            >
                <Topbar store={store} role={role} />
                <div style={{ height: "calc(100% - 50px)" }}>
                    <PolotnoContainer className="polotno-app-container">
                        {role === "cty" || role === "manage" ? (
                            <SidePanelWrap>
                                <SidePanel store={store} sections={sections} defaultSection="text" />
                            </SidePanelWrap>
                        ) : null}
                        <WorkspaceWrap>
                            {role === "cty" || role === "manage" ? <Toolbar store={store} /> : null}
                            <Workspace
                                backgroundColor="black"
                                // pageBorderColor="gray" // border around page
                                activePageBorderColor="tomato" // border around active page. It will be used only if you have several pages. Otherwise just pageBorderColor will be used
                                store={store}
                                pageControlsEnabled={role === "cty" || role === "manage" ? true : false}
                            />
                            {role === "cty" || role === "manage" ? <ZoomButtons store={store} /> : null}
                        </WorkspaceWrap>
                    </PolotnoContainer>
                </div>
            </div>
        );
    } else {
        return (
            <h1
                style={{
                    backgroundColor: "tomato",
                    color: "white",
                }}
            >
                Không tìm thấy đơn hàng
            </h1>
        );
    }
};

export default App;
