import React from "react";
import { observer } from "mobx-react-lite";
import { MenuItem, Button, Spinner, SpinnerSize } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import { getImageSize } from "polotno/utils/image";

import { ImagesGrid } from "polotno/side-panel/images-grid";

export const PhotosPanelPngAll = observer(({ store }) => {
    const [images, setImages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [startItem, setStartItem] = React.useState(0);
    const [endItem, setEndItem] = React.useState(0);
    const [pageNum, setPageNum] = React.useState(1);

    const [listCat, setListCat] = React.useState([]);
    const [catSelected, setCatSelected] = React.useState("Chọn nhóm");
    const [pathPng, setPathPng] = React.useState("");

    const [isEnd, setIsEnd] = React.useState(false);

    const pageSize = 20;

    React.useEffect(() => {
        // load data
        fetch("./assets/images/png_cat/index.json")
            .then((res) => res.json())
            .then((data) => {
                setListCat(data);
            });
    }, []);

    React.useEffect(() => {
        console.log("useEffect loadMore");
        console.log("useEffect startItem", startItem);
        console.log("useEffect endItem", endItem);
        console.log("useEffect pathPng", pathPng);
        console.log("useEffect pageNum", pageNum);
        if (endItem - startItem > pageSize - 1) {
            setIsEnd(false);
            loadImages(endItem - pageSize, endItem, true);
        } else {
            setIsEnd(true);
            loadImages(startItem, endItem, true);
        }
    }, [pathPng]);

    async function loadImages(start, end, firstPage = false) {
        if (pathPng) {
            setIsLoading(true);
            await new Promise((resolve) => {
                setTimeout(() => {
                    let myImgs = [];
                    for (let index = start; index < end; index++) {
                        myImgs.push({
                            url: pathPng + "/png" + index + ".png",
                        });
                    }
                    setIsLoading(false);
                    setPageNum(pageNum + 1);
                    if (firstPage) {
                        setImages(myImgs);
                    } else {
                        setImages(myImgs.concat(images));
                    }
                }, 3000);
            }).catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
        }
    }

    const loadMore = (start = null, end = null, firstPage = false) => {
        if (!isLoading && !isEnd) {
            if (!start && !end) {
                let offet = (pageNum - 1) * pageSize;
                if (endItem - startItem > offet - 1) {
                    setIsEnd(false);
                    let getStart = endItem - offet - pageSize;
                    let getEnd = endItem - offet;
                    getStart = getStart <= startItem ? startItem : getStart;
                    console.log({
                        getStart,
                        getEnd,
                        pageNum,
                    });
                    loadImages(getStart, getEnd, firstPage);
                } else {
                    setIsEnd(true);
                    console.log({
                        endItem,
                        startItem,
                        pageNum,
                    });
                    loadImages(endItem, startItem, firstPage);
                }
            } else {
                loadImages(start, end, firstPage);
            }
        } else {
            console.log("is loading");
        }
    };

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Select
                items={listCat}
                itemPredicate={(query, e) => {
                    return `${e.name.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
                }}
                itemRenderer={(e) => {
                    return (
                        <MenuItem
                            label={e.name.toString()}
                            key={e.name.toString()}
                            onClick={() => {
                                setCatSelected(e.name.toString());
                                let path = "./assets/images/png_cat/" + e.folder;
                                setIsEnd(false);
                                setPathPng(path);
                                setStartItem(e.start);
                                setEndItem(e.end);
                                setPageNum(1);
                                setImages([]);
                                console.log(path);
                                console.log(e.start);
                                console.log(e.end);
                            }}
                        />
                    );
                }}
                noResults={<MenuItem disabled={true} text="Không có kết quả" />}
            >
                <Button text={catSelected} rightIcon="caret-down" />
            </Select>

            {/* <div
                style={{
                    overflow: "scroll",
                    height: 185,
                }}
            >
                {listCat &&
                    listCat.map((e, i) => {
                        return (
                            <Button
                                className={"bp3-button"}
                                style={{
                                    margin: 2,
                                }}
                                key={i}
                                // minimal
                                onClick={() => {
                                    let path = "./assets/images/png_cat/" + e.folder;
                                    setIsEnd(false);
                                    setPathPng(path);
                                    setStartItem(e.start);
                                    setEndItem(e.end);
                                    setPageNum(1);
                                    setImages([]);
                                    console.log(path);
                                    console.log(e.start);
                                    console.log(e.end);
                                }}
                            >
                                {e.name}
                            </Button>
                        );
                    })}
            </div> */}
            <div>
                <Button
                    className={"bp3-button"}
                    style={{
                        margin: 2,
                        backgroundColor: "tomato",
                    }}
                    onClick={() => {
                        loadMore();
                    }}
                >
                    Tải thêm
                </Button>
            </div>
            {isLoading && (
                <div>
                    <Spinner intent={null} size={SpinnerSize.STANDARD} value={null} />
                </div>
            )}
            {/* you can create yur own custom component here */}
            {/* but we will use built-in grid component */}
            <ImagesGrid
                images={images}
                getPreview={(image) => image.url}
                onSelect={async (image) => {
                    const { width, height } = await getImageSize(image.url);
                    store.activePage.addElement({
                        type: "image",
                        src: image.url,
                        width: 300,
                        height: (300 / width) * height,
                        x: 0,
                        y: 0,
                    });
                }}
                rowsNumber={2}
                isLoading={isLoading}
                loadMore={loadMore}
            />
        </div>
    );
});
