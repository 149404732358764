import React from "react";
import { observer } from "mobx-react-lite";
import { InputGroup, Button, Spinner, SpinnerSize } from "@blueprintjs/core";

// import { getImageSize } from "polotno/utils/image";

import { ImagesGrid } from "polotno/side-panel/images-grid";

export const PhotosPanelBg_mypham = observer(({ store }) => {
    const [images, setImages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [startItem, setStartItem] = React.useState(0);
    const [endItem, setEndItem] = React.useState(0);
    const [pageNum, setPageNum] = React.useState(1);
    const pageSize = 20;

    React.useEffect(() => {
        // load data
        fetch("./assets/images/bg_mypham/index.json")
            .then((res) => res.json())
            .then((data) => {
                console.log("data:", data);
                setStartItem(data.start);
                setEndItem(data.end);
                setImages([]);
                loadMore(data.end - pageSize, data.end);
            });
    }, []);

    async function loadImages(start, end) {
        // here we should implement your own API requests
        setIsLoading(true);
        await new Promise((resolve) => {
            setTimeout(() => {
                let myImgs = [];
                for (let index = start; index < end; index++) {
                    myImgs.push({
                        url: "./assets/images/bg_mypham/bg" + index + ".jpg",
                    });
                }
                setIsLoading(false);
                setPageNum(pageNum + 1);
                setImages(myImgs.concat(images));
            }, 3000);
        });
    }

    // const isReachingEnd = isEmpty || (data && data[0].total_pages === size);

    const loadMore = (start = null, end = null) => {
        if (!isLoading) {
            // let offet = (pageNum - 1) * pageSize;
            // let getStart = startItem + offet;
            // let getEnd = startItem + offet + pageSize;
            // getEnd = getEnd > endItem ? endItem : getEnd;
            if (!start && !end) {
                let offet = (pageNum - 1) * pageSize;
                let getStart = endItem - offet - pageSize;
                let getEnd = endItem - offet;
                getStart = getStart <= startItem ? startItem : getStart;

                console.log({
                    getStart,
                    getEnd,
                    pageNum,
                });
                loadImages(getStart, getEnd);
            } else {
                loadImages(start, end);
            }
        }
    };

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <InputGroup
                leftIcon="search"
                placeholder="Search..."
                onChange={(e) => {
                    loadImages();
                }}
                style={{
                    marginBottom: "20px",
                }}
            />
            <div>
                Mỹ phẩm:{" "}
                <Button
                    className={"bp3-button"}
                    style={{
                        margin: 2,
                    }}
                    onClick={() => {
                        loadMore();
                    }}
                >
                    Tải thêm
                </Button>
            </div>
            {isLoading && (
                <div>
                    <Spinner intent={null} size={SpinnerSize.STANDARD} value={null} />
                </div>
            )}
            {/* you can create yur own custom component here */}
            {/* but we will use built-in grid component */}
            <ImagesGrid
                images={images}
                getPreview={(image) => image.url}
                onSelect={async (image) => {
                    // const { width, height } = await getImageSize(image.url);
                    store.activePage.addElement({
                        type: "image",
                        src: image.url,
                        width: 1080,
                        height: 1080,
                        x: 0,
                        y: 0,
                    });
                }}
                rowsNumber={2}
                isLoading={isLoading}
                loadMore={loadMore}
            />
        </div>
    );
});
