const DOMAIN_URL = "https://studio.tonykiet.vn";
// const DOMAIN_URL = "https://dev.tonykiet.vn";
// const DOMAIN_URL = "http://localhost:3000";

const TIME_OUT_SAVE = 1000 * 60 * 10;

const PEXELS_API_KEY =
  "563492ad6f91700001000001e898881700244d4bac18859a6279ddb7";

const PIXABAY_API_KEY = "22563185-9af7039a0313bced0f04b5c53";

export { DOMAIN_URL, TIME_OUT_SAVE, PEXELS_API_KEY, PIXABAY_API_KEY };
