import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Navbar, Alignment, AnchorButton, Dialog, Classes, Spinner, SpinnerSize } from "@blueprintjs/core";
import { downloadFile } from "polotno/utils/download";

import axios from "axios";
import { useLocation } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import { DOMAIN_URL, TIME_OUT_SAVE } from "./constants";

import styled from "polotno/utils/styled";

const NavbarContainer = styled("div")`
    @media screen and (max-width: 500px) {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100vw;
    }
`;

const NavInner = styled("div")`
    @media screen and (max-width: 500px) {
        display: flex;
    }
`;

export default observer(({ store, role }) => {
    const inputRef = React.useRef();

    const [transact, setTransact] = React.useState("");
    const [createBy, setCreateBy] = React.useState(0);
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [showMsg, setShowMsg] = React.useState(false);
    const [showMsgSave, setShowMsgSave] = React.useState(false);
    const [showMsgNewFromChat, setShowMsgNewFromChat] = React.useState(false);
    const [messageModal, setMessageModal] = React.useState("");
    const [designOri, setDesignOri] = React.useState(null);

    const [pageNo, setPageNo] = React.useState(1);
    const pageSize = role === "cus" || role === "view" || window.innerWidth < 650 ? 1 : 5;

    // customer can download image
    const [canDownload, setCanDownload] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [transactData, setTransactData] = React.useState([]);

    let location = useLocation();

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setIsMobile(window.innerWidth < 650 ? true : false);
        });
    });

    React.useEffect(() => {
        let paramsSearch = location.search.replace("?", "");
        let myParams = qs.parse(paramsSearch);
        setTransact(myParams["transact"]);
        setCreateBy(myParams["uID"] ? myParams["uID"] : 0);
    }, [location]);

    React.useEffect(() => {
        if (pageNo) {
            setLoadingModal(true);
            // clear last page
            const pagesIds = store.pages.map((p) => p.id);
            store.deletePages(pagesIds);

            setTimeout(() => {
                console.log("pageNo", pageNo);
                if (designOri == undefined) {
                    // alert("Không có code");
                    console.log("Không có code");
                    setTimeout(() => {
                        setLoadingModal(false);
                    }, 2000);
                } else {
                    if (parseInt(pageNo) === 0) {
                        store.loadJSON(designOri);
                        setTimeout(() => {
                            setLoadingModal(false);
                        }, 1000);
                    } else {
                        let start = (pageNo - 1) * pageSize;
                        let end = start + pageSize;

                        const designPage = Object.assign({}, designOri);
                        designPage.pages = designPage.pages.filter((e, i) => {
                            return i >= start && i < end;
                        });
                        console.log("designPage", designPage);
                        store.loadJSON(designPage);
                        setTimeout(() => {
                            setLoadingModal(false);
                        }, 2000);
                    }
                }
            }, 1000);
        }
    }, [pageNo]);

    React.useEffect(() => {
        if (transact.length > 0) {
            setTimeout(() => {
                getDesignCode();
                getDepartment(transact);
            }, 1000);
        }
    }, [transact]);

    const getDepartment = (transact) => {
        try {
            let urlRequest = DOMAIN_URL + "/api/getData.php?func=getDepartment&transact=" + transact;
            fetch(urlRequest)
                .then((resJSON) => resJSON.json())
                .then((res) => {
                    console.log("getDepartment", res);
                    if (res["status"] === 1) {
                        let data = res["data"][0];
                        setTransactData(data);
                        setCanDownload(data["canDownload"] && data["canDownload"] === "1" ? true : false);
                    }
                    console.log("getDepartment", res);
                })
                .catch((e) => {
                    console.log("error getDepartment", e);
                });

            // const response = await axios.get(urlRequest, {
            //   headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     "Access-Control-Request-Headers": "*",
            //   },
            // });
        } catch (error) {
            console.error(error);
        }
    };

    const timeOutSave = () => {
        if (role === "cty" || role === "manage") {
            setTimeout(() => {
                setShowMsgSave(true);
            }, TIME_OUT_SAVE);
        }
    };

    const getDesignCode = async () => {
        setLoadingModal(true);
        apiGetDesignCode(transact).then((res) => {
            console.log("apiGetDesignCode res", res);
            if (res == undefined) {
                console.log("Không có code");
                setTimeout(() => {
                    setLoadingModal(false);
                }, 2000);
            } else {
                try {
                    setDesignOri(res);
                    timeOutSave();

                    // clear last page
                    const pagesIds = store.pages.map((p) => p.id);
                    store.deletePages(pagesIds);

                    setTimeout(() => {
                        console.log("getDesignCode pageNo", pageNo);
                        if (parseInt(pageNo) === 0) {
                            store.loadJSON(res);
                            setTimeout(() => {
                                setLoadingModal(false);
                            }, 1000);
                        } else {
                            let start = (pageNo - 1) * pageSize;
                            let end = start + pageSize;

                            const designPage = Object.assign({}, res);
                            if (designPage.pages == undefined) {
                                setLoadingModal(false);
                                setMessageModal("Có lỗi xảy ra trong lúc lấy code design - " + transact);
                                setShowMsg(true);
                            } else {
                                designPage.pages = designPage.pages.filter((e, i) => {
                                    return i >= start && i < end;
                                });
                                console.log("designPage", designPage);
                                store.loadJSON(designPage);
                                setTimeout(() => {
                                    setLoadingModal(false);
                                }, 2000);
                            }
                        }
                    }, 1000);
                } catch (error) {
                    console.log("error getDesignCode", error);
                    setLoadingModal(false);
                    apiGetDesignCode(transact, "-text").then((res) => {
                        try {
                            setDesignOri(res);
                            store.loadJSON(res);
                            setLoadingModal(false);
                            timeOutSave();
                        } catch (error) {
                            console.log("error getDesignCode-text", error);
                            setMessageModal("Có lỗi xảy ra trong lúc lấy code design - " + transact);
                            setShowMsg(true);
                            // setLoadingModal(false);
                            // getDesign();
                        }
                    });
                }
            }
        });
    };

    const getDesignBK = async (bk) => {
        setLoadingModal(true);
        apiGetDesignCode(transact, bk).then((res) => {
            try {
                setDesignOri(res);
                store.loadJSON(res);
                setLoadingModal(false);
                timeOutSave();
            } catch (error) {
                console.log("error getDesignBK", error);
                setMessageModal("Có lỗi xảy ra trong lúc lấy code design - " + transact);
                setShowMsg(true);
                setLoadingModal(false);
            }
        });
    };

    const createDesignCode = async () => {
        setLoadingModal(true);
        apiCreateDesignCode_v2(transact).then((res) => {
            try {
                console.log("createDesignCode", res);
                if (res !== 0) {
                    const pagesIds = store.pages.map((p) => p.id);
                    store.deletePages(pagesIds);

                    store.loadJSON(res);

                    setMessageModal("Lấy đơn hàng " + transact + " thành công");
                    setShowMsg(true);

                    timeOutSave();
                } else {
                    setMessageModal("Lưu đơn hàng từ chat thất bại - " + transact);
                    setShowMsg(true);
                }
                setLoadingModal(false);
            } catch (error) {
                console.log("error createDesignCode", error);
                setLoadingModal(false);
            }
        });
    };

    const updateDesignOri = async () => {
        setLoadingModal(true);
        const jsonDesign = store.toJSON();
        if (parseInt(pageNo) === 0) {
            setTimeout(() => {
                setDesignOri(jsonDesign);
                saveDesignCode(jsonDesign);
            }, 1000);
        } else {
            const newDesign = designOri ? JSON.parse(JSON.stringify(designOri)) : jsonDesign;
            const storeDesign = Object.assign({}, jsonDesign);
            storeDesign.pages.forEach((e, i) => {
                let ni = (pageNo - 1) * pageSize + i;
                console.log("ni", ni);
                newDesign.pages[ni] = e;
            });
            console.log("newDesign", newDesign);
            setDesignOri(newDesign);
            saveDesignCode(newDesign);
        }
        setTimeout(() => {
            setLoadingModal(false);
        }, 2000);
    };

    const saveDesignCode = async (jsonDesign) => {
        setLoadingModal(true);
        apiSaveDesignToFile(transact, jsonDesign).then((res) => {
            try {
                console.log(res);
                setTimeout(() => {
                    setLoadingModal(false);
                }, 2000);

                setMessageModal("Lưu đơn hàng " + transact + " thành công");
                setShowMsg(true);

                timeOutSave();
                console.log("jsonDesign.pages.length", jsonDesign.pages.length);
                postDesignLog(jsonDesign.pages.length);
            } catch (error) {
                console.log("error saveDesignCode", error);
                setTimeout(() => {
                    setLoadingModal(false);
                }, 2000);
            }
        });
    };

    const postDesignLog = (numOfPage = 0) => {
        // setLoadingModal(true);
        apiPostDesignLog(transact, numOfPage, createBy).then((res) => {
            try {
                console.log("apiPostDesign res", res);
            } catch (error) {
                console.log("apiPostDesign error", error);
            }
        });
    };

    // #region API
    // const apiGetDesign = async (transact) => {
    //     try {
    //         let urlRequest = DOMAIN_URL + "/api/getData.php?func=getTransactDesign&transact=" + transact;

    //         const response = await axios.get(urlRequest, {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Access-Control-Request-Headers": "*",
    //             },
    //         });
    //         let json = await response.data;
    //         return json;
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const apiPostDesignLog = async (transact, numOfPage, createBy, download = false) => {
        try {
            let urlRequest = DOMAIN_URL + "/api/postData.php?func=postTransactDesign";
            let data = {
                transact: transact,
                numOfPage: numOfPage,
                createBy: createBy,
            };
            if (download) {
                data.downloadBy = role === "cus" ? "Khách" : "Nhân viên";
            }
            const response = await axios.post(urlRequest, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers": "*",
                },
            });
            let json = await response.data;
            return json;
        } catch (error) {
            console.error(error);
        }
    };

    const apiSaveDesignToFile = async (transact, design, createBy) => {
        try {
            let urlRequest = DOMAIN_URL + "/api/postData.php?func=saveDesignToFile&transact=" + transact;
            var myJSONString = JSON.stringify(design);

            let data = {
                transact: transact,
                design: myJSONString,
                createBy: createBy,
            };
            const response = await axios.post(urlRequest, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers": "*",
                },
            });
            let json = await response.data;
            return json;
        } catch (error) {
            console.error(error);
        }
    };

    const apiCreateDesignCode = async (transact) => {
        try {
            let urlRequest = DOMAIN_URL + "/api/postData.php?func=createDesignCode&transact=" + transact;
            const response = await axios.get(urlRequest, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers": "*",
                },
            });
            let json = await response.data;
            return json;
        } catch (error) {
            console.error(error);
        }
    };

    const apiCreateDesignCode_v2 = async (transact) => {
        try {
            let urlRequest = DOMAIN_URL + "/api/postData.php?func=createDesignCode_v2&transact=" + transact;
            const response = await axios.get(urlRequest, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers": "*",
                },
            });
            let json = await response.data;
            return json;
        } catch (error) {
            console.error(error);
        }
    };

    const apiGetDesignCode = async (transact, getText = null) => {
        try {
            let urlRequest = DOMAIN_URL + "/api/code/" + transact + (getText ? getText : "") + ".json";
            console.log("apiGetDesignCode urlRequest:", urlRequest);
            const response = await axios.get(urlRequest, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Request-Headers": "*",
                },
            });
            let json = await response.data;
            return json;
        } catch (error) {
            console.error(error);
        }
    };
    // #endregion

    const cusConfirmDesign = async () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Xác nhận!")) {
            try {
                let urlRequest = DOMAIN_URL + "/api/getData.php?func=cusConfirmDesign&transact=" + transact;
                fetch(urlRequest)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            console.log(result);
                            alert("Bạn đã xác nhận thành công!");
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <NavbarContainer className="bp3-navbar" style={{ display: "flex", justifyContent: "center", height: 110 }}>
            <NavInner>
                {role === "cty" || role === "manage" ? (
                    <Navbar.Group align={Alignment.LEFT}>
                        <label htmlFor="load-project">
                            <Button
                                icon="folder-open"
                                minimal
                                onClick={() => {
                                    document.querySelector("#load-project").click();
                                }}
                            >
                                Open
                            </Button>
                            <input
                                type="file"
                                id="load-project"
                                accept=".json,.polotno"
                                ref={inputRef}
                                style={{ width: "180px", display: "none" }}
                                onChange={(e) => {
                                    var input = e.target;

                                    if (!input.files.length) {
                                        return;
                                    }

                                    var reader = new FileReader();
                                    reader.onloadend = function () {
                                        var text = reader.result;
                                        let json;
                                        try {
                                            json = JSON.parse(text);
                                        } catch (e) {
                                            alert("Can not load the project.");
                                        }

                                        if (json) {
                                            store.loadJSON(json);
                                        }
                                    };
                                    reader.onerror = function () {
                                        alert("Can not load the project.");
                                    };
                                    reader.readAsText(input.files[0]);
                                }}
                            />
                        </label>
                        {role === "manage" || role === "cty" ? (
                            <div>
                                <Button
                                    icon="download"
                                    minimal
                                    onClick={() => {
                                        let randomNum = moment().format("YYMMDDHHmmss");
                                        const json = designOri;

                                        const url =
                                            "data:text/json;base64," +
                                            window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
                                        downloadFile(url, "simontu-" + transact + "-" + randomNum + ".json");
                                    }}
                                >
                                    Tải code
                                </Button>
                            </div>
                        ) : null}
                        {role === "manage" || role === "cty" ? (
                            <div>
                                <Button
                                    icon="download"
                                    minimal
                                    onClick={() => {
                                        let randomNum = moment().format("YYMMDDHHmmss");
                                        const json = store.toJSON();

                                        const url =
                                            "data:text/json;base64," +
                                            window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
                                        downloadFile(url, "simontu-" + transact + "-" + randomNum + ".json");
                                    }}
                                >
                                    Tải code 2
                                </Button>
                            </div>
                        ) : null}
                        <Button
                            minimal
                            onClick={() => {
                                getDesignBK("-bk1");
                            }}
                        >
                            BK1
                        </Button>

                        <Button
                            minimal
                            onClick={() => {
                                getDesignBK("-bk2");
                            }}
                        >
                            BK2
                        </Button>
                        <Button
                            minimal
                            onClick={() => {
                                setShowMsgNewFromChat(true);
                            }}
                        >
                            Tạo code chat
                        </Button>
                        <AnchorButton
                            minimal
                            href={`https://studio.tonykiet.vn/api/rendervideo/?transact=${transact}`}
                            target="_blank"
                        >
                            Làm video
                        </AnchorButton>

                        <Button
                            icon="cloud-upload"
                            minimal
                            onClick={() => {
                                updateDesignOri();
                            }}
                        >
                            Lưu code
                        </Button>
                        <div className="bp3-select .modifier">
                            {window.innerWidth < 650 ? (
                                <select
                                    onChange={(e) => {
                                        var input = e.target;
                                        setPageNo(input.value);
                                    }}
                                >
                                    <option value="0">Tất cả trang</option>
                                    <option value="1" selected>
                                        1
                                    </option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>
                                    <option value="41">41</option>
                                    <option value="42">42</option>
                                    <option value="43">43</option>
                                    <option value="44">44</option>
                                    <option value="45">45</option>
                                    <option value="46">46</option>
                                    <option value="47">47</option>
                                    <option value="48">48</option>
                                    <option value="49">49</option>
                                    <option value="50">50</option>
                                    <option value="51">51</option>
                                    <option value="52">52</option>
                                    <option value="53">53</option>
                                    <option value="54">54</option>
                                    <option value="55">55</option>
                                    <option value="56">56</option>
                                    <option value="57">57</option>
                                    <option value="58">58</option>
                                    <option value="59">59</option>
                                    <option value="60">60</option>
                                    <option value="61">61</option>
                                    <option value="62">62</option>
                                    <option value="63">63</option>
                                    <option value="64">64</option>
                                    <option value="65">65</option>
                                    <option value="66">66</option>
                                    <option value="67">67</option>
                                    <option value="68">68</option>
                                    <option value="69">69</option>
                                    <option value="70">70</option>
                                    <option value="71">71</option>
                                    <option value="72">72</option>
                                    <option value="73">73</option>
                                    <option value="74">74</option>
                                    <option value="75">75</option>
                                    <option value="76">76</option>
                                    <option value="77">77</option>
                                    <option value="78">78</option>
                                    <option value="79">79</option>
                                    <option value="80">80</option>
                                    <option value="81">81</option>
                                    <option value="82">82</option>
                                    <option value="83">83</option>
                                    <option value="84">84</option>
                                    <option value="85">85</option>
                                    <option value="86">86</option>
                                    <option value="87">87</option>
                                    <option value="88">88</option>
                                    <option value="89">89</option>
                                    <option value="90">90</option>
                                    <option value="91">91</option>
                                    <option value="92">92</option>
                                    <option value="93">93</option>
                                    <option value="94">94</option>
                                    <option value="95">95</option>
                                    <option value="96">96</option>
                                    <option value="97">97</option>
                                    <option value="98">98</option>
                                    <option value="99">99</option>
                                    <option value="100">100</option>
                                </select>
                            ) : (
                                <select
                                    onChange={(e) => {
                                        var input = e.target;
                                        setPageNo(input.value);
                                    }}
                                >
                                    <option value="0">Tất cả trang</option>
                                    <option value="1" selected>
                                        1-5
                                    </option>
                                    <option value="2">6-10</option>
                                    <option value="3">11-15</option>
                                    <option value="4">16-20</option>
                                    <option value="5">21-25</option>
                                    <option value="6">26-30</option>
                                    <option value="7">31-35</option>
                                    <option value="8">36-40</option>
                                    <option value="9">41-45</option>
                                    <option value="10">46-50</option>
                                    <option value="11">51-55</option>
                                    <option value="12">56-60</option>
                                    <option value="13">61-65</option>
                                    <option value="14">66-70</option>
                                    <option value="15">71-75</option>
                                    <option value="16">76-80</option>
                                    <option value="17">81-85</option>
                                    <option value="18">86-90</option>
                                    <option value="19">91-95</option>
                                    <option value="20">96-100</option>
                                </select>
                            )}
                        </div>
                        {role === "manage" ? (
                            <div>
                                <Button
                                    icon="floppy-disk"
                                    minimal
                                    onClick={() => {
                                        let randomNum = moment().format("YYMMDDHHmmss");
                                        store.saveAsImage({
                                            pixelRatio: 2,
                                            fileName: transact + "-" + randomNum + ".jpg",
                                            mimeType: "image/jpg",
                                            pageId: store.activePage.id,
                                        });
                                    }}
                                >
                                    Tải 1 hình
                                </Button>
                                <Button
                                    icon="floppy-disk"
                                    minimal
                                    onClick={() => {
                                        let numOfPage = designOri == undefined ? 0 : designOri.pages.length;
                                        // log who download
                                        apiPostDesignLog(transact, numOfPage, createBy, true).then((res) =>
                                            console.log("apiPostDesignLog download", res)
                                        );

                                        let randomNum = moment().format("YYMMDDHHmmss");
                                        store.pages.forEach((obj, index) => {
                                            setTimeout(function () {
                                                store.saveAsImage({
                                                    fileName: transact + "-" + index + "-" + randomNum + ".jpg",
                                                    // pixelRatio: 2,
                                                    mimeType: "image/jpg",
                                                    pageId: obj.id,
                                                });
                                            }, index * 500);
                                        });
                                    }}
                                >
                                    Tải hình
                                </Button>
                            </div>
                        ) : null}
                    </Navbar.Group>
                ) : // role customer
                role === "cus" ? (
                    <div>
                        <div>
                            {transactData?.cusName ? transactData?.cusName : "Khách"} ơi, Simon Tu Production mời xem
                            hình chúng tôi đã thiết kế cho bạn. Đơn hàng: {transact}
                        </div>
                        <div>
                            {role === "cus" && canDownload ? (
                                <Button
                                    icon="floppy-disk"
                                    minimal
                                    onClick={() => {
                                        let numOfPage = designOri == undefined ? 0 : designOri.pages.length;
                                        // log who download
                                        apiPostDesignLog(transact, numOfPage, createBy, true).then((res) =>
                                            console.log("apiPostDesignLog download", res)
                                        );

                                        let randomNum = moment().format("YYMMDDHHmmss");
                                        store.pages.forEach((obj, index) => {
                                            setTimeout(function () {
                                                store.saveAsImage({
                                                    fileName: transact + "-" + index + "-" + randomNum + ".jpg",
                                                    // pixelRatio: 2,
                                                    mimeType: "image/jpg",
                                                    pageId: obj.id,
                                                });
                                            }, index * 500);
                                        });
                                    }}
                                >
                                    Tải hình
                                </Button>
                            ) : null}
                            {window.innerWidth < 650 ? null : (
                                <AnchorButton minimal href="https://tonykiet.vn" target="_blank">
                                    Phần mềm chỉnh sửa ảnh của Simon Tứ Production
                                </AnchorButton>
                            )}
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Navbar.Group style={{ paddingBottom: 10 }}>
                                <Button
                                    style={{ backgroundColor: "tomato" }}
                                    className={"bp3-button"}
                                    onClick={() => {
                                        if (pageNo > 1) setPageNo(pageNo - 1);
                                        else setPageNo(1);
                                    }}
                                >
                                    Hình trước
                                </Button>
                                <div style={{ marginRight: 10, marginLeft: 10 }}> {pageNo} </div>
                                <Button
                                    style={{ backgroundColor: "tomato" }}
                                    className={"bp3-button"}
                                    onClick={() => {
                                        setPageNo(pageNo + 1);
                                    }}
                                >
                                    Hình sau
                                </Button>
                            </Navbar.Group>
                            <div>
                                <Button
                                    style={{ backgroundColor: "tomato" }}
                                    className={"bp3-button"}
                                    onClick={() => {
                                        cusConfirmDesign();
                                    }}
                                >
                                    Đồng ý thiết kế
                                </Button>
                            </div>
                        </div>
                        <Navbar.Group align={Alignment.RIGHT}></Navbar.Group>
                    </div>
                ) : null}

                <Dialog
                    icon="info-sign"
                    onClose={() => setLoadingModal(false)}
                    title="Đang xử lý"
                    isOpen={loadingModal}
                    style={{
                        width: "80%",
                        maxWidth: "700px",
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <Spinner intent={null} size={SpinnerSize.STANDARD} value={null} />
                        <div>Đang xử lý, vui lòng chờ</div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={() => setLoadingModal(false)}>Close</Button>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    icon="info-sign"
                    onClose={() => setShowMsg(false)}
                    title="Thông báo"
                    isOpen={showMsg}
                    style={{
                        width: "80%",
                        maxWidth: "700px",
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div>{messageModal}</div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={() => setShowMsg(false)}>Close</Button>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    icon="info-sign"
                    onClose={() => {
                        timeOutSave();
                        setShowMsgSave(false);
                        // alert("Vui lòng bấm nút lưu");
                    }}
                    title="Thông báo"
                    isOpen={showMsgSave}
                    style={{
                        width: "80%",
                        maxWidth: "700px",
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div>Bạn có muốn lưu dữ liệu đơn hàng này không?</div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    if (transact.length > 0) {
                                        // const jsonDesign = store.toJSON();
                                        updateDesignOri();
                                        // saveDesignCode(jsonDesign);
                                    } else {
                                        alert("Vui lòng nhập mã đơn hàng cần lưu code");
                                    }
                                    setShowMsgSave(false);
                                }}
                            >
                                Có
                            </Button>
                            <Button
                                onClick={() => {
                                    timeOutSave();
                                    setShowMsgSave(false);
                                }}
                            >
                                Không
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    icon="info-sign"
                    onClose={() => {
                        // timeOutSave();
                        setShowMsgNewFromChat(false);
                    }}
                    title="Thông báo"
                    isOpen={showMsgNewFromChat}
                    style={{
                        width: "80%",
                        maxWidth: "700px",
                    }}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div>Cảnh báo, việc này sẽ xóa toàn bộ dữ liệu hiện tại để lấy dữ liệu mới!</div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                onClick={() => {
                                    createDesignCode();
                                    setShowMsgNewFromChat(false);
                                }}
                            >
                                Có
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowMsgNewFromChat(false);
                                }}
                            >
                                Không
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </NavInner>
        </NavbarContainer>
    );
});
