import React from "react";
import { observer } from "mobx-react-lite";
import { ImagesGrid } from "polotno/side-panel/images-grid";

export const ContentPanel = observer(({ store }) => {
  const [items, setItems] = React.useState([]);
  
  React.useEffect(() => {
    // load data
    fetch("./assets/text/contents.json")
      .then((res) => res.json())
      .then((data) => {
        console.log("data:", data);
        setItems(data.items);
      });
  }, []);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <p>Simon Tứ Contents: </p>
      {/* you can create yur own custom component here */}
      {/* but we will use built-in grid component */}
      <ImagesGrid
        images={items}
        getPreview={(e) => e.preview}
        getImageClassName={(e) => "polotno-text-preview-plain"}
        onSelect={async (e) => {
          store.activePage.addElement(
            Object.assign(Object.assign({}, e), {
              type: "text",
              fontFamily: "UTMAvo",
              text: e.content,
              x: 0,
              y: 0,
            })
          );
        }}
        rowsNumber={4}
        isLoading={false}
        loadMore={false}
      />
    </div>
  );
});
