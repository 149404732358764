import React from "react";
import { observer } from "mobx-react-lite";
import { InputGroup } from "@blueprintjs/core";

import { getImageSize } from "polotno/utils/image";

import { ImagesGrid } from "polotno/side-panel/images-grid";

export const PhotosPanelPngWed = observer(({ store }) => {
  const [images, setImages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [startItem, setStartItem] = React.useState(0);
  const [endItem, setEndItem] = React.useState(0);
  const [pageNum, setPageNum] = React.useState(1);
  const pageSize = 20;

  React.useEffect(() => {
    // load data
    fetch("./assets/images/png_wed/index.json")
      .then((res) => res.json())
      .then((data) => {
        setStartItem(data.start);
        setEndItem(data.end);
        setImages([]);
        if (data.end - data.start > pageSize - 1) {
          loadMore(data.end - pageSize, data.end);
        } else {
          loadMore(data.start, data.end);
        }
      });
  }, []);

  async function loadImages(start, end) {
    setIsLoading(true);
    await new Promise((resolve) => {
      setTimeout(() => {
        let myImgs = [];
        for (let index = start; index < end; index++) {
          myImgs.push({
            url: "./assets/images/png_wed/" + index + ".png",
          });
        }
        setIsLoading(false);
        setPageNum(pageNum + 1);
        setImages(images.concat(myImgs));
      }, 3000);
    });
  }

  const loadMore = (start = null, end = null) => {
    if (!isLoading) {
      if (!start && !end) {
        let offet = (pageNum - 1) * pageSize;
        if (endItem - startItem > offet - 1) {
          console.log("get1");
          let getStart = endItem - offet - pageSize;
          let getEnd = endItem - offet;
          getStart = getStart <= startItem ? startItem : getStart;
          console.log({
            getStart,
            getEnd,
            pageNum,
          });
          loadImages(getStart, getEnd);
        } else {
          console.log("get2");
          loadImages(endItem, startItem);
        }
      } else {
        console.log("get start end");
        loadImages(start, end);
      }
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          loadImages();
        }}
        style={{
          marginBottom: "20px",
        }}
      />
      <p>Simon Tứ PNG: </p>
      {/* you can create yur own custom component here */}
      {/* but we will use built-in grid component */}
      <ImagesGrid
        images={images}
        getPreview={(image) => image.url}
        onSelect={async (image) => {
          const { width, height } = await getImageSize(image.url);
          store.activePage.addElement({
            type: "image",
            src: image.url,
            width: 300,
            height: (300 / width) * height,
            x: 0,
            y: 0,
          });
        }}
        rowsNumber={2}
        isLoading={isLoading}
        loadMore={loadMore}
      />
    </div>
  );
});
